body {
  margin: 0;
  font-family: 'Basier Square', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes hover {

  0%,
  20% {
    width: 5%;
    position: absolute;
    left: 95%;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  2%,
  6%,
  10% {
    opacity: 0;
  }

  4%,
  8%,
  12% {
    opacity: 1;
  }

  49% {
    width: 100%;
  }

  55% {
    width: 0;
    left: 0;
    background-color: #fff;
  }

  56% {
    width: 0;
    background-color: #FBB900;
  }

  100% {
    width: 100%;
    background-color: #FBB900;
    left: 0;
  }
}


@font-face {
  font-family: Roobert;
  src: url(../public/font/Roobert-Medium.woff2);
}

@font-face {
  font-family: Roobert;
  src: url(../public/font/Roobert-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: Roobert;
  src: url(../public/font/Roobert-SemiBold.woff2);
  font-weight: 700;
}

@font-face {
  font-family: 'Basier Square';
  src: url(../public/font/basiersquare_regular.woff2);
  font-weight: 500;
}

@font-face {
  font-family: 'Basier Square';
  src: url(../public/font/basiersquare_medium.woff2);
  font-weight: 600;
}

@font-face {
  font-family: 'Basier Square';
  src: url(../public/font/basiersquare_bold.woff2);
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 6px;       
}

::-webkit-scrollbar-thumb {  
  border-radius: 10px;
  background-color: #373737; 
}

::-webkit-scrollbar-track {    
  background-color: inherit;
}

.swiper-wrapper {
  margin-bottom: 20px;
}

/* data table header */
.kNxEtY {
  font-weight: 600 !important;
  padding: 15px;
  font-size: 1.8rem !important;
}
.video-react .video-react-slider:focus {
  box-shadow: none !important;
}

.video-react .video-react-control:focus:before, .video-react .video-react-control:hover:before, .video-react .video-react-control:focus {
  box-shadow: none !important;
}

.video-react .video-react-control:focus:before, .video-react .video-react-control:hover:before, .video-react .video-react-control:focus {
  box-shadow: none !important;
}

.video-react .video-react-progress-control:hover .video-react-time-tooltip, .video-react .video-react-progress-control:hover .video-react-mouse-display:after, .video-react .video-react-progress-control:hover .video-react-play-progress:after {
  font-weight: 600 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.swiper-pagination-bullet {
  background: white !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 30px !important;
  font-weight: 900;
  color: white;
  opacity: 0;
}

.swiper:hover .swiper-button-next:after,
.swiper:hover .swiper-button-prev:after {
  opacity: 1;
}

.atlassian-textfield-dark-1 {
  background-color: black !important;
  color: white !important;
  border-color: #2e2e2e !important;
  width: 80% !important;
}

.atlassian-label-dark {
  color: white !important;
  font-weight: 700;
  padding: 10px;
  display: flex;
  width: 80%;
}

.atlassian-info-label {
  color: white;
  opacity: 0.7;
  font-weight: 500;
  width: 80%;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}


.required {
  padding-left: 5px;
  color: #F9B715 !important;
}

.atlassian-input-error {
  color: red;
  opacity: 0.7;
  font-weight: 600;
  width: 80%;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

.MuiStepIcon-text {
  font-weight: 700 !important;
}

.MuiStepLabel-label {
  font-weight: 600 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: white;
}

.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  color: black !important;
}

/* .MuiSvgIcon-root {
  color: white;
} */

